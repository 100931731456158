/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery-validation@1.19.5/dist/jquery.validate.min.js
 * - /npm/lazysizes@5.3.2/lazysizes.min.js
 * - /npm/moment@2.29.4/moment.min.js
 * - /npm/moment-range@4.0.2/dist/moment-range.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
